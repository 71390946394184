<template>
	<w-dialog v-model="showDialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="title" @close="close()">
		<v-layout column>
			<v-flex>{{ message }}</v-flex>
			<v-flex v-if="displayLoader" class="mt-3">
				<v-progress-linear :height="2" indeterminate color="primary"></v-progress-linear>
			</v-flex>
			<v-flex v-else-if="currentNodeHasWorkflows && nodeIsFolder" class="mt-3">
				La sélection contient des éléments liés à des flux de travail, toute information liée sera perdue.
			</v-flex>
		</v-layout>
		<template v-slot:actions>
			<v-layout row>
				<v-spacer />
				<w-button flat color="primary" @click="close()">{{ $t('actions.no') }}</w-button>
				<w-button :disabled="displayLoader" color="error" @click="doDelete()">{{ $t('actions.delete') }}</w-button>
			</v-layout>
		</template>
	</w-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'ItemDeletion',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			showDialog: true
		}
	},
	computed: {
		...mapGetters({
			nodeHasWorkflows: 'workflows/nodeHasWorkflows',
			nodeIsLoadingWorkflows: 'workflows/nodeIsLoadingWorkflows',
		}),
		currentNodeIsLoadingWorkflows() {
			return this.nodeIsLoadingWorkflows(this.node?.id)
		},
		currentNodeHasWorkflows() {
			return this.nodeHasWorkflows(this.node?.id)
		},
		model: {
			get() {
				return Array.isArray(this.value) ? this.value : [this.value] || []
			},
			set(value) {
				this.$emit('input', value)
			}
		},
		node() {
			return this.model.length === 1 ? this.model[0] : null;
		},
		nodeIsFolder() {
			return this.node?.is_folder
		},
		message() {
			if (this.node?.is_folder) {
				return this.$t('documents.delete_folder_message', { itemName: this.node.name })
			}
			if (this.node && !this.node.is_folder) {
				return this.$t('documents.delete_document_message', { itemName: this.node.name })
			}
			return this.$tc('documents.delete_elements_message', this.model.length)
		},
		title() {
			switch (this.modelType) {
				case 'folders':
					return this.$tc('documents.delete_folder', this.model.length);
				case 'documents':
					return this.$tc('documents.delete_documents', this.model.length);
				case 'mixed':
					return this.$t('documents.delete_elements');
				default:
					return null;
			}
		},
		displayLoader() {
			if (! this.nodeIsFolder || ! this.currentNodeHasWorkflows) {
				return false
			}

			return this.currentNodeIsLoadingWorkflows;
		},
		successMessage() {
			switch (this.modelType) {
				case 'folders':
					return this.$tc('ecm.folder_deleted', this.model.length);
				case 'documents':
					return this.$tc('ecm.documents_deleted', this.model.length);
				case 'mixed':
					return this.$tc('ecm.elements_deleted', this.model.length);
				default:
					return null;
			}
		},
		modelType() {
			if (this.model.length === 0) {
				return null;
			}
			if (this.model.every(node => node.is_folder)) {
				return 'folders';
			}
			if (this.model.every(node => !node.is_folder)) {
				return 'documents';
			}
			return 'mixed';
		}
	},
	destroyed() {
		this.close()
	},
	methods: {
		async doDelete() {
			const onSuccessMessage = this.successMessage
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			const nodesToDelete = [...this.model]
			this.eventBus.emit(this.events.DELETION_STARTED)
			this.model = []
			this.close()

			try {
				await Promise.all(
					nodesToDelete.map(async node => {
						if (node.is_folder) {
							return this.service.deleteFolder(this.vendorId, node)
						}
						await this.service.deleteDocument(this.vendorId, node)
						return this.eventBus.emit(this.events.DOCUMENT_DELETED, node)
					})
				)
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, onSuccessMessage)
			} finally {
				this.eventBus.emit(this.events.DELETION_ENDED)
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
			}
		},
		close() {
			this.showDialog = false
			this.$emit('close')
		}
	}
}
</script>
